var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"iteration-section-wrapper",class:[
      { 'is-story': _vm.isStory },
      { 'squared-stories': _vm.format.storiesShape === 'square' },
      { 'semi-stories': _vm.format.storiesShape === 'semi' },
      { 'portrait-stories': _vm.format.storiesShape === 'portrait' },
      { 'has-shadow': _vm.format.thumbnailShadow },
    ],style:(_vm.iterationSectionStyle),on:{"mousedown":function($event){return _vm.$emit('click-done', _vm.videoIndex)},"touchstart":function($event){return _vm.$emit('click-done', _vm.videoIndex)}}},[(
        _vm.isStory &&
        (_vm.format.storiesHasBorder === true ||
          _vm.format.storiesHasBorder === undefined)
      )?_c('div',{class:[
        'solid-border',
        {
          animate:
            _vm.clickedInlineIndex === _vm.videoIndex &&
            _vm.format.storiesShape !== 'round',
        },
        {
          'animate-round-stories':
            _vm.clickedInlineIndex === _vm.videoIndex &&
            (!_vm.format.storiesShape || _vm.format.storiesShape === 'round'),
        },
      ],style:(_vm.solidBorderStyle)}):_vm._e(),(_vm.isStory && _vm.clickedInlineIndex === _vm.videoIndex)?_c('div',{class:[
        'dotted-border',
        { 'round-dotted-border': _vm.format.storiesShape === 'round' },
        {
          animate:
            _vm.clickedInlineIndex === _vm.videoIndex &&
            _vm.format.storiesShape !== 'round',
        },
        {
          'animate-round-stories':
            _vm.clickedInlineIndex === _vm.videoIndex &&
            (!_vm.format.storiesShape || _vm.format.storiesShape === 'round'),
        },
      ],style:(_vm.dottedBorderStyle)}):_vm._e(),_c('VideoPlayer',{staticClass:"vidjet-carousel-item",style:(_vm.videoPlayerDynamicStyle),attrs:{"video-index":_vm.videoIndex,"clicked-index":_vm.isInlined ? _vm.clickedInlineIndex : 0}})],1),(_vm.isStory && _vm.textUnderStories)?_c('div',{staticClass:"story-title-wrapper"},[_c('h3',{staticClass:"story-title",style:([
        _vm.textOptionsStyle,
        _vm.shouldLowerFontSize,
        {
          'font-size': _vm.fontSize,
          'line-height': _vm.lineHeight,
        },
      ])},[_vm._v(" "+_vm._s(_vm.decodeText(_vm.textUnderStories))+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }