<template>
  <!-- TODO: adding this component was always a mistake, to be removed -->
  <div>
    <div
      class="iteration-section-wrapper"
      :class="[
        { 'is-story': isStory },
        { 'squared-stories': format.storiesShape === 'square' },
        { 'semi-stories': format.storiesShape === 'semi' },
        { 'portrait-stories': format.storiesShape === 'portrait' },
        { 'has-shadow': format.thumbnailShadow },
      ]"
      :style="iterationSectionStyle"
      @mousedown="$emit('click-done', videoIndex)"
      @touchstart="$emit('click-done', videoIndex)"
    >
      <div
        v-if="
          isStory &&
          (format.storiesHasBorder === true ||
            format.storiesHasBorder === undefined)
        "
        :class="[
          'solid-border',
          {
            animate:
              clickedInlineIndex === videoIndex &&
              format.storiesShape !== 'round',
          },
          {
            'animate-round-stories':
              clickedInlineIndex === videoIndex &&
              (!format.storiesShape || format.storiesShape === 'round'),
          },
        ]"
        :style="solidBorderStyle"
      ></div>
      <div
        v-if="isStory && clickedInlineIndex === videoIndex"
        :class="[
          'dotted-border',
          { 'round-dotted-border': format.storiesShape === 'round' },
          {
            animate:
              clickedInlineIndex === videoIndex &&
              format.storiesShape !== 'round',
          },
          {
            'animate-round-stories':
              clickedInlineIndex === videoIndex &&
              (!format.storiesShape || format.storiesShape === 'round'),
          },
        ]"
        :style="dottedBorderStyle"
      ></div>
      <VideoPlayer
        class="vidjet-carousel-item"
        :video-index="videoIndex"
        :clicked-index="isInlined ? clickedInlineIndex : 0"
        :style="videoPlayerDynamicStyle"
      />
    </div>
    <div v-if="isStory && textUnderStories" class="story-title-wrapper">
      <h3
        class="story-title"
        :style="[
          textOptionsStyle,
          shouldLowerFontSize,
          {
            'font-size': fontSize,
            'line-height': lineHeight,
          },
        ]"
      >
        {{ decodeText(textUnderStories) }}
      </h3>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
import { decodeText } from "@/utils/utils.js";
import { mapGetters } from "vuex";
export default {
  name: "IterationSection",
  components: { VideoPlayer },
  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
    numberOfVideosToDisplay: {
      type: Number,
    },
    cardWidth: {
      type: Number,
    },
    cardHeight: {
      type: Number,
    },
    clickedInlineIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      decodeText,
      lineHeightMultiplier: 1.1,
    };
  },

  computed: {
    ...mapGetters("playerCampaign", ["format", "isStory"]),
    ...mapGetters("videos", ["videos", "getVideoByIndex"]),
    ...mapGetters("player", ["isMobile"]),

    isInlined() {
      return !this.format?.isFullScreen;
    },
    textOptions() {
      if (this.videoIndex === this.videos.length) {
        return {
          color: "black",
          size: 12,
          isBold: false,
          text: "La vidéo complète",
        };
      }

      return this.getVideoByIndex(this.videoIndex)?.textOptions ?? {};
    },
    textOptionsStyle() {
      if (Object.keys(this.format.textOptions ?? {}).length > 0) {
        return {
          color: this.format.textOptions.color,
          fontWeight: this.format.textOptions.isBold ? "bold" : "initial",
          fontStyle: this.format.textOptions.isItalic ? "italic" : "normal",
          textDecoration: this.format.textOptions.isUnderlined
            ? "underline"
            : "none",
        };
      } else {
        return {};
      }
    },
    shouldLowerFontSize() {
      if (
        this.$route.params.siteId === "90d4f9ae-5daa-448a-b9f9-3c2c4a1fa2b5"
      ) {
        return { "font-size": "10px" };
      }

      return {};
    },
    textUnderStories() {
      return (
        this.textOptions.text ||
        this.getVideoByIndex(this.videoIndex)?.videoTitle
      );
    },
    fontSize() {
      const baseSize = this.format?.textOptions?.size || 12;
      if (this.isMobile && baseSize > 14) {
        return "14px";
      }
      return `${baseSize}px`;
    },
    lineHeight() {
      const fontSize = this.format?.textOptions?.size || 12;
      return `${fontSize * this.lineHeightMultiplier}px`;
    },
    videoPlayerSizeStyle() {
      const baseSize = this.format.storySize || 80;

      let width = baseSize;
      let height = baseSize;

      const portraitRatioFactor = 1.5;

      if (this.format.storiesShape === "portrait") {
        height *= portraitRatioFactor;
      }

      if (this.isStory) {
        if (this.isMobile) {
          width = 80;
          height = 80;

          if (this.format.storiesShape === "portrait") {
            height *= portraitRatioFactor;
          }
        } else {
          width = this.format.storySize;
          height = this.format.storySize;

          if (this.format.storiesShape === "portrait") {
            height *= portraitRatioFactor;
          }
        }

        return {
          width: `${width}px`,
          height: `${height}px`,
        };
      }

      return {};
    },
    videoPlayerDynamicStyle() {
      return this.isStory && !this.isMobile
        ? this.videoPlayerSizeStyle
        : this.makeNonPortraitVideosWider(this.videoIndex);
    },
    iterationSectionStyle() {
      let style = {};

      if (this.isStory && this.format.storySize) {
        const baseSize = this.format.storySize;

        const portraitRatioFactor = 1.5;

        let width = baseSize + 10;
        let height = baseSize + 10;

        if (this.format.storiesShape === "portrait") {
          height = baseSize * portraitRatioFactor + 10;
        }

        if (this.isMobile) {
          width = 80 + 10;
          height = 80 + 10;

          if (this.format.storiesShape === "portrait") {
            height = 80 * portraitRatioFactor + 10;
          }
        }

        style.width = `${width}px`;
        style.height = `${height}px`;

        if (this.format.storiesShape === "square") {
          style.borderRadius = "1px";
        } else if (this.format.storiesShape === "semi") {
          style.borderRadius = "12px";
        }
      }

      if (this.format.thumbnailShadow) {
        style.borderRadius = `${this.format.cornerShape}px`;
      }

      return style;
    },
    solidBorderStyle() {
      let borderRadius = "50%";
      if (
        this.format.storiesShape === "square" ||
        this.format.storiesShape === "portrait"
      ) {
        borderRadius = "1px";
      } else if (this.format.storiesShape === "semi") {
        borderRadius = "16px";
      }
      return `border: 3px solid ${this.format.borderColor}; border-radius: ${borderRadius}`;
    },
    dottedBorderStyle() {
      let borderRadius = "50%";
      if (
        this.format.storiesShape === "square" ||
        this.format.storiesShape === "portrait"
      ) {
        borderRadius = "1px";
      } else if (this.format.storiesShape === "semi") {
        borderRadius = "16px";
      }
      return `border: 3px dotted ${this.format.borderColor}; border-radius: ${borderRadius}`;
    },
  },
  methods: {
    makeNonPortraitVideosWider(videoIndex) {
      if (this.isStory) return;
      return `height: ${this.cardHeight}px; width: ${
        videoIndex === this.clickedInlineIndex &&
        this.videos[videoIndex].width / this.videos[videoIndex].height > 0.99 &&
        this.numberOfVideosToDisplay > 1
          ? this.cardHeight
          : this.cardWidth
      }px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.iteration-section-wrapper {
  cursor: pointer;
  transition: width 0.5s ease-in-out;
  width: 100%;
  overflow: hidden;
}

.is-story .vidjet-carousel-item {
  overflow: hidden;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  cursor: pointer;
}

.is-story .vidjet-carousel-item video {
  border-radius: 50%;
  clip-path: circle(50%);
  -webkit-clip-path: circle(50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.is-story .vidjet-carousel-item {
  mask-image: radial-gradient(circle, white 100%, transparent 100%);
  -webkit-mask-image: radial-gradient(circle, white 100%, transparent 100%);
}

.iteration-section-wrapper.is-story {
  width: 90px;
  height: 90px;
  overflow: visible;
  border-radius: 50%;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iteration-section.is-story {
  clip-path: circle(40%);
  -webkit-clip-path: circle(40%);
}

.squared-stories {
  border-radius: 1px !important;
  .vidjet-carousel-item {
    width: 90px;
    height: 90px;
    border-radius: 1px !important;
  }
}
.portrait-stories {
  border-radius: 1px !important;
  .vidjet-carousel-item {
    width: 90px;
    height: 130px;
    border-radius: 1px !important;
  }
}

.semi-stories {
  border-radius: 12px !important;
  .vidjet-carousel-item {
    width: 90px;
    height: 90px;
    border-radius: 12px !important;
  }
}

.story-title-wrapper {
  width: 100%;
}
.story-title {
  text-align: center;
  width: 90%;
  margin: 4px auto 0;
  line-height: 15px;
}

.solid-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.dotted-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px dotted var(--border-color);
  box-sizing: border-box;
  mask: linear-gradient(90deg, transparent 50%, #000 50%);
  mask-size: 200% 100%;
  animation: moveDots 2s linear infinite;
}

.round-dotted-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: none;
  mask: none;
  animation: none;
  box-sizing: border-box;
}

@keyframes moveDots {
  0% {
    mask-position: 0% 0%;
  }
  100% {
    mask-position: -200% 0%;
  }
}

.inner {
  box-sizing: border-box;
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.animate.solid-border {
  animation: borderAnimation 4s linear infinite;
}

@keyframes borderAnimation {
  0%,
  49.9% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.has-shadow {
  box-shadow: 2px 5px 5px #00000026 !important;
}

.animate-round-stories.dotted-border {
  animation: 2s linear 0s infinite normal none running rotateAnimation;
}

.animate-round-stories.solid-border {
  animation: borderAnimation 4s linear infinite;
}

@keyframes borderAnimation {
  0%,
  49.9% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
